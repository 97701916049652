.no_more_message {
  font-size: 12px;
  color: #bbb;
  margin: 0 0 10px;
  text-align: center;
}

.date_item {
  text-align: center;
  margin: 10px;
  font-size: 10px;
  color: #ccc;
}

.nick_name {
  text-align: center;
}

.message_image_box {
  width: 100px;
  height: 100px;
}

.right_content .message_image_box {
  float: right;
}

.message_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;
}

.file_upload {
  position: relative;
}
.file_input {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  opacity: 0;
}
