.list_container {
  position: relative;
}
.spin_box {
  text-align: center;
  margin: 10px 0;
  position: absolute;
  z-index: 999;
  width: 100%;
  bottom: 0;
  left: 0;
}
