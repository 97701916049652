.login-form {
  width: 300px;
  margin: 60px auto 0;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
